<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import Swal from 'sweetalert2';
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import axios from 'axios';
import { firebase } from "../../../services/firebase";

export default {
  components: {
    Layout,
    PageHeader,
    VueFileAgent,
  },
  data() {
    return {
      zoom: 12,
      maxZoom: 25,
      center: {
        lat: null,
        lng: null
      },
      AlertMarker: [],
      CtzPosition: [],
      selectedPosition: {
        lat: null,
        lng: null
      },
      title: "Lancer une nouvelle intervention",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Nouvelle intervention",
          active: true,
        },
      ],
      spanView: false,
      isRecording: false,
      mediaRecorder: null,
      chunks: [],
      audioURL: null,
      alert: {},
      data: {
        agent: null,
        short_code: null,
        title: null,
        lieu: null,
        description: null,
        selected: false,
        position: null,
        photos: [],
        audios: [],
        videos: [],
        audiosLocal: [],
        files: [],
      },
      alerte: {},
      alerteObject: {},
      coordinates: [],
      agent: null,
      filesData: [],
      options: ["Sécurité Nationale", "Appel à témoin", "Sensibilisation"],
      zones: ["Zone 1", "Zone 2", "Zone 3"],
      zone: null,
      makerpselected: null,
      Markertype: [],
      thematique: null,
      reqError: null,
      reqResp: null,
      userPosition: {},
      AgentPosition: {},
      markers: [],
      markersselect: [],

    };
  },
  mounted() {

    const dataReceived = JSON.parse(localStorage.getItem('myData'));

    console.log('local storage data');
    console.log(dataReceived);
    !navigator.mediaDevices.getUserMedia ? alert('Votre navigateur ne supporte pas l\'enregistrement audio.') : null;


    this.alerte = dataReceived.alerte;
    this.alerteObject = dataReceived.alertObject;
    if (dataReceived.coordinates && dataReceived.coordinates.length > 0) {
      this.coordinates = dataReceived.coordinates;
      console.log(this.coordinates);

    }
    this.userPosition = { lat: this.alerteObject.sender_current_position.coordinates[0], lng: this.alerteObject.sender_current_position.coordinates[1] };
    console.log(this.alerteObject);
    console.log(this.alerte);
    console.log(this.userPosition);
    localStorage.removeItem('myData');


    this.initMap();
    this.getAgentPosition();
    this.interval = setInterval(this.getAgentPosition, 30000);
    // this.getAlertDetails();
    const that = this;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, function (error) {
        alert("Erreur de géolocalisation : " + error.message);
      });
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    ...reqMethods,

    CreateCenterControl(map) {
      const controlButton = document.createElement("button");
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";

      controlButton.textContent = "Centrer la carte";
      controlButton.title = "Click to recenter the map";
      controlButton.type = "button";
      controlButton.addEventListener("click", () => {
        map.setCenter({ lat: this.userPosition.lat, lng: this.userPosition.lng });
      });

      return controlButton;

    },
    initMap() {
      const self = this;
      console.log("init map")

      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(self.userPosition.lat, self.userPosition.lng),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });


      const marker = new google.maps.Marker({
        position: { lat: self.userPosition.lat, lng: self.userPosition.lng },
        map: this.map,
        title: "Position 0",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          scaledSize: new google.maps.Size(50, 50),
        }
      });
      marker.addListener("click", () => {
        console.log("click")
        this.handleMarkerClick({ lat: self.userPosition.lat, lng: self.userPosition.lng });
      });


      if (this.coordinates && this.coordinates.length > 0) {
        this.coordinates.forEach((item, index) => {
          const newmark = new google.maps.Marker({
            position: { lat: item.coordinate.coordinates[0], lng: item.coordinate.coordinates[1] },
            map: this.map,
            title: `Position ${index + 1}`
          });

          newmark.addListener("click", ({ domEvent, latLng }) => {
            console.log("click")
            const { target } = domEvent;
            this.handleMarkerClick({ lat: item.coordinate.coordinates[0], lng: item.coordinate.coordinates[1] })
          });
        });
      }

      this.Markertype.push(marker);

      const centerControlDiv = document.createElement("div");
      // Create the control.
      const centerControl = self.CreateCenterControl(this.map);

      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);


      this.getAgentPositionFromFirestore();
    },


    addMarker(position, title) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(position[0], position[1]),
        map: this.map,
        title: title,
        icon: finalIcon
        // Ajoutez d'autres options de marqueur si nécessaire
      });

      marker.addListener("click", () => {
        this.handleMarkerClick({ lat: position[0], lng: position[1] });
      });

      this.Markertype.push(marker);
    },

    handleMarkerClick(latlong) {
      if (latlong) {
        this.selectedPosition.lat = latlong.lat;
        this.selectedPosition.lng = latlong.lng;
      }
    },

    async getAgentPositionFromFirestore() {
      const self = this;
      firebase.firestore()
        .collection("partages_position")
        .onSnapshot(snap => {
          const agentsPosition = [];
          const infoWindows = [];
          const iconCommon = {
            url: "/maps/gpscar.png",
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0),
          };

          const icons = {
            moto: { ...iconCommon, url: "/maps/gpsmoto.png" },
            car: { ...iconCommon, url: "/maps/gpscar.png" },
            pieds: { ...iconCommon, url: "/maps/mapuserwalker.png" },
            helico: { ...iconCommon, url: "/maps/gpscar.png" },
          };

          console.log("snap.docs.length")
          console.log(snap.docs.length)
          for (let i = 0; i < snap.docs.length; i++) {
            var driver = snap.docs[i].data();
            const finalIcon = icons[driver.type_service] || iconCommon;
            const nmaker =
              new google.maps.Marker({
                map: this.map,
                title: driver.type_service,
                icon: finalIcon
              })
            nmaker.addListener("click", ({ domEvent, latLng }) => {
              const { target } = domEvent;
              self.position(driver)
            });

            agentsPosition.push(nmaker);
            agentsPosition[i].setPosition(
              new google.maps.LatLng(driver.latitude, driver.longitude)
            );
            console.log(driver)
            infoWindows.push(new google.maps.InfoWindow({
              disableAutoPan: true
            }));
            /* infoWindows[i].setContent(
                 `<div class="ui header">Agent A </div>`
             );
             infoWindows[i].open(this.map, agentsPosition[i]);*/

          }
        });
    },
    getAgentPosition() {
      const that = this;
      this.getRequest('/account/agents/agent/service/')
        .then(
          function (response) {
            for (const position of response.data) {
              that.markers.push({
                id: position.id,
                position: position.typeservice?.position?.coordinates,
                name: position.username,
                typeAgent: position.typeservice?.type_service,
                agenID: position.typeservice?.agent,
              });
            }

          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    position(data) {
      const that = this;
      Swal.fire({
        title: data.agent,
        text: "Souhaitez-vous affecter cette intervention à cet agent ?",
        imageHeight: 40,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Oui, Affecter !",
      }).then(result => {
        that.data.agent = data.agentId;
        if (result.value) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Affectation effectuée avec succès",
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.addEventListener('dataavailable', this.onDataAvailable);
        this.mediaRecorder.addEventListener('stop', this.onRecordingStopped);
        this.chunks = [];
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Erreur lors de la demande d\'accès à l\'audio:', error);
      }
    },

    async convertBlobToAudioFile(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      const currentDate = new Date();
      const fileName = currentDate.toLocaleString('fr-FR', { timeZone: 'UTC' })
        .replace(/[/:]/g, '-') + '.wav';

      const file = new File([blob], fileName, { type: 'audio/wav' });
      return file;
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },

    onDataAvailable(event) {
      event.data.size > 0 ? this.chunks.push(event.data) : null;
    },

    async onRecordingStopped() {
      const audioBlob = new Blob(this.chunks, { type: 'audio/wav' });
      const audioURL = URL.createObjectURL(audioBlob);
      this.data.audiosLocal.push(audioURL);
      const audioFile = await this.convertBlobToAudioFile(audioURL);
      this.data.audios.push(audioFile);
    },

    deleteAudio(index) {
      this.data.audiosLocal.splice(index, 1);
      this.data.audios.splice(index, 1);
    },
    handleMapClick(event) {
      console.log(event)
      if (event && event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        this.data.position = `POINT(${lat} ${lng})`;
        this.selectedPosition.lat = lat;
        this.selectedPosition.lng = lng;
        const self = this;
        self.deleteMarkers();
        var mmker = new google.maps.Marker({
          position: event.latLng,
          map: self.map,
          title: "Position selectionnée",
        });

        self.Markertype.push(mmker);
      }
    },

    handleMarkerClick(latlong) {
      console.log(latlong)
      if (latlong) {
        const lat = latlong.lat;
        const lng = latlong.lng;
        this.data.position = `POINT(${lat} ${lng})`;
        this.selectedPosition.lat = lat;
        this.selectedPosition.lng = lng;
      }
    },
    setMapOnAll() {
      for (let i = 0; i < this.Markertype.length; i++) {
        this.Markertype[i].setMap(null);
      }
    },
    hideMarkers() {
      this.setMapOnAll();
    },

    deleteMarkers() {
      this.hideMarkers();
      this.Markertype = [];
    },

    onMapClick(event) {
      console.log(event);
      if (event && event.latLng) {
        const lat = event.latLng.lat()
        const lng = event.latLng.lng()
        this.data.position = `POINT(${lat} ${lng})`;
        this.selectedPosition.lat = lat;
        this.selectedPosition.lng = lng;
        if (this.markersselect.length === 0) {
          this.markersselect.push({ position: { lat: lat, lng: lng } });
        } else {
          this.markersselect = []
          this.markersselect.push({ position: { lat: lat, lng: lng, title: "Position selectionnée" } });
        }
      }
    },
    // getAlertDetails() {
    //   const that = this;
    //   const id = { id: parseInt(this.$route.params.id) };
    //   this.postRequest({
    //     route: '/alert/alert-spontaneous/findbyid/',
    //     data: id,
    //   })
    //     .then(
    //       function (response) {
    //         const data = response.data;
    //         console.log(data)
    //         that.data.short_code = data.alerts[0]?.short_code;
    //         that.data.title = data.alerts[0]?.thematic.name;
    //         that.data.description = data.messages[0].message;

    //         if (data.coordinates) {
    //           data.coordinates.forEach((item, index) => {
    //             const newPosition = {
    //               position: {
    //                 lat: item.coordinate.coordinates[0],
    //                 lng: item.coordinate.coordinates[1],
    //                 title: `Position ${index + 1}`
    //               }
    //             }

    //             const npmaker = new google.maps.Marker({
    //               position: newPosition.position,
    //               map: that.map,
    //               title: newPosition.position.title,
    //               icon: {
    //                 url: "/maps/position.svg",
    //                 scaledSize: new google.maps.Size(50, 50),
    //                 origin: new google.maps.Point(0, 0),
    //                 anchor: new google.maps.Point(0, 0),
    //               }
    //             });

    //             npmaker.addListener("click", ({ domEvent, latLng }) => {
    //               const { target } = domEvent;
    //               that.handleMarkerClick(newPosition.position)
    //             });

    //           })
    //         }
    //         that.userPosition = {
    //           lat: data.alerts[0].sender_current_position.coordinates[0],
    //           lng: data.alerts[0].sender_current_position.coordinates[1]
    //         };
    //       },
    //       function (error) {
    //         that.reqError = error;
    //       }
    //     )
    // },
    filterFiles() {
      this.filesData.forEach(element => {
        if (element.file.type.includes('image/')) {
          this.data.photos.push(element.file);
        } else if (element.file.type == 'application/pdf') {
          this.data.files.push(element.file);
        } else if (element.file.type.includes('video/')) {
          this.data.videos.push(element.file);
        } else if (element.file.type.includes('audio/')) {
          this.data.audios.push(element.file);
        }
      })
    },
    moveToIntervention(response) {
      Swal.fire(response.data.message, "L'intervention à été lancée!", "success");
      this.$router.push({ path: `/interventionProgress/${response.data.id}` });
    },
    async lunchIntervention() {
      const savedResponse = null;
      const that = this;
      this.spanView = true;
      ((this.filesData.length !== 0) ? this.filterFiles() : null);
      const formData = new FormData();
      formData.append('agent', parseInt(this.data.agent));
      formData.append('alert', parseInt(this.$route.params.id));
      formData.append('from_alert', true);
      formData.append('title', this.data.title);
      formData.append('lieu', this.data.lieu);
      formData.append('position', this.data.position);
      formData.append('description', this.data.description);
      const fileCategories = ['photos', 'videos', 'audios', 'files'];
      for (const category of fileCategories) {
        for (const file of this.data[category]) {
          formData.append(category, file);
        }
      }

      for (const [key, value] of formData.entries()) {
        if (!value && !['photos', 'videos', 'audios', 'files'].includes(key)) {
          Swal.fire({
            title: 'Erreur',
            text: `Le champ ${key} est réquis et ne peut pas être vide.`,
            icon: 'error',
            confirmButtonClass: 'btn btn-primary w-xs mt-2',
            buttonsStyling: false,
            showCloseButton: true,
          });
          that.spanView = false;
          return;
        }
      }
      console.log('formData:', Array.from(formData.entries()));
      console.log('FormData content:', Array.from(formData.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
      const token = localStorage.getItem('token');
      console.log('token:', token);

      try {
        console.log('formData:', Array.from(formData.entries()));
        console.log('FormData content:', Array.from(formData.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));

        const response = await axios.post(
          'https://api.alcit.sims-technologie.com/api/v1/intervention/intervention/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          }
        );
        that.spanView = false;
        Swal.fire(response.data.message, "L'intervention à été lancée!", "success");
        this.$router.push({ path: `/interventionProgress/${response.data.id}` });
      } catch (error) {
        that.spanView = false;
        that.reqError = error;
        console.log(error);

        Swal.fire({
          title: "Oops...",
          text: "Erreur lors du lancement de l'intervention",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    /* handleMarkerClick() {
       this.Marker = [];
       this.selectedPosition = [];
       this.data.position = [];
     }*/
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Code d'alerte</label>
                <div class="col-lg-10">
                  <input id="code" v-model="alerte.short_code" name="code" type="text" class="form-control"
                    placeholder="Renseigner le code de l'alerte" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Titre</label>
                <div class="col-lg-10">
                  <input id="title" v-model="data.title" name="title" type="text" class="form-control"
                    placeholder="Saisir le titre de l'alerte" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Lieux</label>
                <div class="col-lg-10">
                  <input id="adresse" v-model="data.lieu" name="adresse" type="text" class="form-control"
                    placeholder="Renseigner l'adresse" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Enregistrer audio</label>
                <div class="col-lg-10">
                  <div class="audio-recorder">
                    <div class="recorder-controls">
                      <button type="button" @click="startRecording" :disabled="isRecording"
                        :class="{ 'record-button': true, 'pulse': isRecording }">
                        <span v-if="!isRecording"><i class="mdi mdi-microphone-outline"></i></span>
                        <span v-else><i class="mdi mdi-microphone-settings"></i></span>
                      </button>
                      <button type="button" @click="stopRecording" :disabled="!isRecording" class="stop-button"><i
                          class="mdi mdi-stop"></i>
                      </button>
                    </div>
                    <div class="audio-player" v-if="data.audiosLocal.length > 0">
                      <div class="audio-item" v-for="(audio, index) in data.audiosLocal" :key="index">
                        <audio :src="audio" controls></audio>
                        <button type="button" @click="deleteAudio(index)" class="delete-button">
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Fichiers associés</label>
                <div class="col-lg-10">
                  <VueFileAgent :deletable="true" :maxSize="'50MB'" :maxFiles="20" :meta="true" :multiple="true"
                    :uploadUrl="uploadUrl" :accept="'image/*,video/*,audio/*,.pdf'" v-model="filesData" />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Coordonnées géographique</label>
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-6">
                      <input id="lat" placeholder="latitude" :disabled="true" v-model="selectedPosition.lat" type="text"
                        name="latitude" class="form-control" />
                    </div>
                    <div class="col-lg-6">
                      <input id="lat" placeholder="longitude" :disabled="true" v-model="selectedPosition.lng" type="text"
                        name="longitude" class="form-control" />
                    </div>
                  </div>
                </div>
                <label for="title" class="col-form-label col-lg-2"></label>
                <div class="col-lg-10">
                  <section style="height: 700px;" ref="map" class="map"></section>
                </div>
              </div>


              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Message d'intervention</label>
                <div class="col-lg-10">
                  <textarea id="projectdesc" v-model="data.description" class="form-control" rows="4"
                    placeholder="Donnez des instruction détaillés"></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="reset" title="Ne pas diffuser maintenant" class="btn btn-primary">
                    Annuler l'intervention
                  </button>
                  <button @click="lunchIntervention()" type="button" class="btn btn-success" style="float: right"
                    :disabled="spanView">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Lancer l'intervention
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.recorder-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity .3s, transform .3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: 1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.record-button {
  background-color: #28a745;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: -50;
}

.stop-button {
  background-color: #dc3545;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
}

.audio-player {
  width: 100%;
}

.audio-item {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}
</style>